/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMobile } from '~/utils/hook-functions';

type Props = {
  heading: string;
  text: string;
  whiteBackground?: boolean;
  small: boolean;
  link: string;
  headingLevel: number;
  height?: string;
};

const Teaser: React.FC<Props> = ({
  heading,
  text,
  whiteBackground,
  small,
  link,
  headingLevel,
  height,
}) => {
  const mobile = useMobile();
  const { i18n } = useTranslation();

  let headingText;
  switch (headingLevel) {
    case 1:
      headingText = <h1>{heading}</h1>;
      break;
    case 2:
      headingText = <h2>{heading}</h2>;
      break;
    case 3:
      headingText = <h3>{heading}</h3>;
      break;
    case 4:
      headingText = <h4>{heading}</h4>;
      break;
    default:
      headingText = <div>{heading}</div>;
  }

  return (
    <>
      <Container
        css={{
          /* stylelint-disable */
          backgroundColor: whiteBackground ? 'white' : 'var(--primary-color)',
          maxWidth: mobile ? '' : small ? '600px' : '1200px',
          width: mobile ? '' : small ? '600px' : '1200px',
          height: mobile ? '' : small ? '400px' : height || '300px',
          /* stylelint-enable */
        }}
      >
        <SmallContainer
          css={{
            width: mobile ? '' : small ? '400px' : '',
          }}
        >
          {headingText}
          {text}
          <br />
          <br />
          <Link to={link}>&gt; {i18n.language === 'de' ? 'mehr' : 'more'}</Link>
        </SmallContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
`;

const SmallContainer = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
    margin: auto;
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  div,
  h2,
  h3,
  h4 {
    font-size: 1.5rem;
    font-weight: normal;
  }

  a {
    padding-top: 1rem;
  }

  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  vertical-align: center;
  text-align: center;
  color: black;
  font-size: var(--primary-font-size);
  line-height: 1.5;
`;

export default Teaser;
